<template>
    <div class="dm_settings_page h-100">
        <!-- Navigation -->
        <header-vue></header-vue>

        <!-- Page Content -->
        <div class="container">
          <div class="row dm_settings_head_wrapper pt-5 d-flex justify-content-center">

            <div class="cell example example1 w-100 modal-lg m-3" id="example-1">
              <form id="#payment-form" action='/charge'>
                <fieldset class="p-3">

                  <div class="row">
                    <div class="col-12 pb-4">
                    <label for="example1-name" data-tid="elements_examples.form.name_label">Name</label>
                    <input class="pt-1 pb-2" id="example1-name" data-tid="elements_examples.form.name_placeholder" type="text" placeholder="Jane Doe" required="" autocomplete="name">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 pb-4">
                    <label for="example1-email" data-tid="elements_examples.form.email_label">Email</label>
                    <input class="pt-1 pb-2" id="example1-email" data-tid="elements_examples.form.email_placeholder" type="email" placeholder="janedoe@gmail.com" required="" autocomplete="email">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 pb-4">
                    <label for="example1-phone" data-tid="elements_examples.form.phone_label">Phone</label>
                    <input class="pt-1 pb-2" id="example1-phone" data-tid="elements_examples.form.phone_placeholder" type="tel" placeholder="(941) 555-0123" required="" autocomplete="tel">
                    </div>
                  </div>
                </fieldset>
                <fieldset class="p-3">
                  <div class="row">
                    <div class="col-12">
                    <div id="example1-card"></div>
                    </div>
                  </div>
                </fieldset>
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <button class="dm_btn_dark_solid" type="button" data-tid="elements_examples.form.pay_button" @click="subscribe">Subscribe</button>
                  </div>
                </div>

                <div class="error pt-4 text-center align-item-center" role="alert">
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                    <path class="base" fill="#000" d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"></path>
                    <path class="glyph" fill="#FFF" d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"></path>
                  </svg>
                  <span class="message"></span>
                </div>
              </form>
              <div class="success text-center p-3">
                <div class="icon">
                  <svg width="84px" height="84px" viewBox="0 0 84 84" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <circle class="border" cx="42" cy="42" r="40" stroke-linecap="round" stroke-width="4" stroke="#000" fill="none"></circle>
                    <path class="checkmark" stroke-linecap="round" stroke-linejoin="round" d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338" stroke-width="4" stroke="#000" fill="none"></path>
                  </svg>
                </div>
                <h3 class="title" data-tid="elements_examples.success.title">Payment successful</h3>
                <a class="reset" href="#" @click="reset">
                  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <path fill="#000000" d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- /.container -->
        <footer-vue></footer-vue>
    </div>
</template>
<script>

import Headervue from '../partials/header-vue'
import Footervue from '../partials/footer-vue'
import {API_STRIPE_KEY} from '../../constants'

// var profile_url = '/profile';
export default {
    name: "payment",
    data: () => ({
        user : [],
        stripe: '',
        elements: '',
        card: '',
        exampleName: '',
        example: '',
        form: '',
        errorMessage: '',
    }),
    components: {
      'header-vue': Headervue,
      'footer-vue': Footervue,
    },
    methods: {
        async getAuthUser() {
            let responce = await this.$store.dispatch("getAuthUser");
            if (responce.status) {
                responce.data.data.details = window.$.parseJSON(responce.data.data.details);
                this.user = responce.data.data;
            }
        },
        async stripeTokenHandler(token){

          let form = {
            token_id: token.id
          };

          // Submit the form with the token ID.
          // Insert the token ID into the form so it gets submitted to the server
          this.example.classList.add('submitting');
          let responce = await this.$store.dispatch("startSubscription", form);
          this.example.classList.remove('submitting');

          if (responce.data.status != '') {
            this.example.classList.add('submitted');
            window.notify(responce.data.status, responce.data.message);
            if (responce.data.status) {
              window.$('.success').show();
              setTimeout(function(){
                window.location.href = '/dashboard';
              }, 1000);
            }
            else
            {
              window.$('.error').show();
              this.errorMessage.innerText = responce.data.message;
            }
          }
          else{
            window.$('.error').show();
            this.errorMessage.innerText = responce.data.message;
            window.notify(false, responce.data.message);
          }

          return false;
        },
        enableInputs: function () {
          var _this = this;
          Array.prototype.forEach.call(
            _this.form.querySelectorAll(
              "input[type='text'], input[type='email'], input[type='tel']"
            ),
            function(input) {
              input.removeAttribute('disabled');
            }
          );
        },
        disableInputs: function () {
          var _this = this;
          Array.prototype.forEach.call(
            _this.form.querySelectorAll(
              "input[type='text'], input[type='email'], input[type='tel']"
            ),
            function(input) {
              input.setAttribute('disabled', 'true');
            }
          );
        },
        triggerBrowserValidation: function () {
          // The only way to trigger HTML5 form validation UI is to fake a user submit
          // event.
          var submit = document.createElement('input');
          submit.type = 'submit';
          submit.style.display = 'none';
          this.form.appendChild(submit);
          submit.click();
          submit.remove();
        },
        reset: function () {
          // Resetting the form (instead of setting the value to `''` for each input)
          // helps us clear webkit autofill styles.
          this.form.reset();

          // Clear each Element.
          this.card.clear();

          // Reset error state as well.
          window.$('.error').hide();

          // Resetting the form does not un-disable inputs, so we need to do it separately:
          this.enableInputs();
          this.example.classList.remove('submitted');
        },
        subscribe: function () {
          // Trigger HTML5 validation UI on the form if any of the inputs fail
          // validation.
          var _this = this;
          var plainInputsValid = true;
          Array.prototype.forEach.call(_this.form.querySelectorAll('input'), function(input)
          {
            if (input.checkValidity && !input.checkValidity()) {
              plainInputsValid = false;
              return;
            }
          });
          if (!plainInputsValid) {
            _this.triggerBrowserValidation();
            return;
          }

          // Show a loading screen...
          _this.example.classList.add('submitting');

          // Disable all inputs.
          _this.disableInputs();

          // Gather additional customer data we may have collected in our form.
          var name = _this.form.querySelector('#' + _this.exampleName + '-name');
          var address1 = _this.form.querySelector('#' + _this.exampleName + '-address');
          var city = _this.form.querySelector('#' + _this.exampleName + '-city');
          var state = _this.form.querySelector('#' + _this.exampleName + '-state');
          var zip = _this.form.querySelector('#' + _this.exampleName + '-zip');
          var additionalData = {
            name: name ? name.value : undefined,
            address_line1: address1 ? address1.value : undefined,
            address_city: city ? city.value : undefined,
            address_state: state ? state.value : undefined,
            address_zip: zip ? zip.value : undefined,
          };

          // Use Stripe.js to create a token. We only need to pass in one Element
          // from the Element group in order to create a token. We can also pass
          // in the additional customer data we collected in our form.
          _this.stripe.createToken(_this.card, additionalData).then(function(result) {
            // Stop loading!
            _this.example.classList.remove('submitting');

            if (result.error) {
              // Inform the user if there was an error.
              window.$('.error').show();
              _this.errorMessage.innerText = result.error.message;
            } else {
              // Send the token to your server.
              // example.querySelector('.token').innerText = result.token.id;
              // example.classList.add('submitted');
              _this.example.classList.add('submitting');
              _this.stripeTokenHandler(result.token);
            }
          });
        },
        registerElements: function () {
          var _this = this;
          // Listen for errors from each Element, and show error messages in the UI.
          var savedErrors = {};
          var elements = [this.card];
          elements.forEach(function(element, idx) {
            element.on('change', function(event) {
              if (event.error) {
                window.$('.error').show();
                savedErrors[idx] = event.error.message;
                _this.errorMessage.innerText = event.error.message;
              } else {
                savedErrors[idx] = null;

                // Loop over the saved errors and find the first one, if any.
                var nextError = Object.keys(savedErrors)
                  .sort()
                  .reduce(function(maybeFoundError, key) {
                    return maybeFoundError || savedErrors[key];
                  }, null);

                if (nextError) {
                  // Now that they've fixed the current error, show another one.
                  _this.errorMessage.innerText = nextError;
                } else {
                  // The user fixed the last error; no more errors.
                  window.$('.error').hide();
                }
              }
            });
          });

        }
    },
    mounted(){
      this.elements = this.stripe.elements({
        fonts: [{
          cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
        }, ],
        // Stripe's examples are localized to specific languages, but if
        // you wish to have Elements automatically detect your user's locale,
        // use `locale: 'auto'` instead.
        locale: window.__exampleLocale
      });
      this.card = this.elements.create('card', {
        iconStyle: 'solid',
        style: {
          base: {
            iconColor: '#f44336',
            color: '#495057',

            ':-webkit-autofill': {
              color: '#fce883',
            },
            '::placeholder': {
              color: '#231f20',
            },
          },
          invalid: {
            iconColor: '#f44336',
            color: '#f44336',
          },
        },
      });
      this.card.mount('#example1-card');
      this.registerElements();

      this.exampleName = 'example1';
      this.example = document.querySelector('.'+this.exampleName);

      this.form = this.example.querySelector('form');
      this.errorMessage = window.$('.message')[0];
      window.$('.error').hide();
      window.$('.success').hide();
    },
    created() {
        this.getAuthUser();
        this.stripe = window.Stripe(API_STRIPE_KEY);
    }
};


</script>